<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, alertController, toastController, loadingController, useBackButton, useIonRouter } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    
  },


  created(){

    //this.$router.push('/index');
  },

  
methods:{
      async presentLoading(message, timeout){

         if(!timeout)
            timeout = 3000;
        
       this.loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: message,
          duration: timeout,
        });
        
      await this.loading.present();
      },

      
      dismissLoading() {
      
        
       this.loading.dismiss();
      
     
    },

   async alert(header,message){
         const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: header,
          message: message,
          buttons: [
            {
              text: 'Ok',
              id: 'confirm-button',
              handler: () => {
                 //this.$router.replace({path: '/index'});
              },
            },
          ],
        });
      alert.present();
    },

     
    async toast(response, time){

        if(!time)
          time = 3000;

         const toast = await toastController.create({

                  message: response,
                  duration: time,
                  animation: true,
                  position: 'middle'

              })
              toast.present();
        //   console.log(    
             //);

      },

}
});

</script>

<style>
a{
  text-decoration: none;
}

</style>