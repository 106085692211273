<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="dashboard" href="/vendor/dashboard">
          <ion-icon :icon="homeOutline" />
          <ion-label>Home</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="products" href="/vendor/products">
          <ion-icon :icon="appsOutline" />
          <ion-label>Products</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="shops" href="/vendor/shops">
          <ion-icon :icon="fileTrayFullOutline" />
          <ion-label>Shops</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script >
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse, square, personCircleSharp, homeOutline, appsOutline, fileTrayFullOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      ellipse, 
      square, 
      personCircleSharp,
      homeOutline,
      appsOutline,
      fileTrayFullOutline
    }
  }
});
</script>
