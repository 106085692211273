import { defineStore } from 'pinia'
import axios from 'axios'
import {Storage} from '@ionic/storage';
const store = new Storage();
export const useStore = defineStore('storeId', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      platform:'',
      id: 0,
      name: 'Abrar',
      pic_url: '',
     // pic_url: '',
      user_id:'',
      r: false,
      meetup_id:'',
      matchmaker_id:'',
      paystack_public_key: 'pk_live_e58241e21a8404efd479bbbc39dc68b8409cacad' ,
      matchmaker_mini:{'name': 'loading'},
      forum_data:{   
      edit_post:{},
      view_user:{}

      },
      user_data:{
        'id':'',
        'name':'',
        'pin':'',
        'token':'',
        'unseen_notifications':'',
        'kyc':{
          'id':null,
          'name':null,
          'status':null,
          'id_type':null,
          'id_picture':null,
          'id_type':null,
          'id_number':null,
        }
       
      },
    products:[
                {
                    'id':1,
                    'name':'Covered Shoes',
                    category_id:3,
                    vendor: {
                        'id':2,
                        'name':'Arewa Failry Used',
                        'description':'Discount shop...we buy and sell used household items in good condition like furnitures, electronics, and the rest',
                        'category':'Services',
                       
                        'picture': '/vendors/Arewafairlyused.png',
                        'state':'Abuja',
                        'lga':'AMAC',
                        'created_at':'11-09-2022'
                    },
                    'description':'Nice covered shoes very affordable with great quality',
                    'sub_category':'fashion',
                    'price':9000,
                    'picture': '/products/shoes2.jpg',
                    'state':'Kaduna',
                    'lga':'Kaduna North',
                    'created_at':'11-09-2022'
                },
                {
                    'id':2,
                    category_id:2,
                    vendor: {
                        'id':2,
                        'name':'Arewa Failry Used',
                        'description':'Discount shop...we buy and sell used household items in good condition like furnitures, electronics, and the rest',
                        'category':'Services',
                       
                        'picture': '/vendors/Arewafairlyused.png',
                        'state':'Abuja',
                        'lga':'AMAC',
                        'created_at':'11-09-2022'
                    },
                    'name':'Set of spoons',
                    'description':'Set of spoons for kitchen',
                    'sub_category':'utensils',
                    'price':13500,
                    'picture': '/products/setofspoons.jpg',
                    'state':'Kano',
                    'lga':'Kano Municipal',
                    'created_at':'12-09-2022'
                },
                {
                    'id':3,
                    category_id:3,
                    vendor:{
                        'id':3,
                        'name':'Kulu Beddings',
                        'description':'',
                        'category':'fashion',
                        'picture': '/vendors/KuluBeddings.png',
                        'state':'Katsina',
                        'lga':'Katsina',
                        'created_at':'11-09-2022'
                    },
                    'name':'Matalan Girls Floral Dress (2-3 yrs)',
                    'description':'Cute and comfy, this soft jersey dress is perfect for everyday dressing. Styled with an ochre base and an all-over floral print, the design features short sleeves and button detailing - perfect for adventures in the outdoors!',
                    'sub_category':'clothing',
                    'price':4000,
                    'picture': '/products/dress.jpg',
                    'state':'Kaduna',
                    'lga':'Tarauni',
                    'created_at':'11-09-2022'
                },
                {
                    'id':4,
                    category_id:5,
                    vendor: {
                        'id':3,
                        'name':'Kulu Beddings',
                        'description':'',
                        'category':'fashion',
                        'picture': '/vendors/KuluBeddings.png',
                        'state':'Katsina',
                        'lga':'Katsina',
                        'created_at':'11-09-2022'
                    },
                    'name':'Colorful Bedsheet',
                    'description':'Bed sheet for medium size bed',
                    'sub_category':'bedsheet',
                    'price':5000,
                    'picture': '/products/bedsheet.jpg',
                    'state':'Kano',
                    'lga':'Nasaeawa',
                    'created_at':'13-09-2022'
                },
                {
                    'id':5,
                    category_id:6,
                    vendor: {
                        'id':4,
                        'name':'DUBANNI SUPPLEMENTS',
                        'description':'Discount shop...we buy and sell used household items in good condition like furnitures, electronics, and the rest',
                        'category':' Beauty and Personal Care',
                        'picture': '/vendors/DUBANNISUPPLEMENTS.png',
                        'state':'Kaduna',
                        'lga':'Zaria',
                        'created_at':'11-09-2022'
                    },
                    'name':'Baby Overall',
                    'description':'Luxury baby overall, Turkey brand Size: 0-3,3-6,6-9',
                    'sub_category':'babies and kids',
                    'price':5300,
                    'picture': '/products/baby.jpg',
                    'state':'Kaduna',
                    'lga':'Kaduna North',
                    'created_at':'11-09-2022'
                },
                {
                    'id':6,
                     category_id:3,
                     vendor: {
                        'id':5,
                        'name':'Aysher_Maishanu_textiles',
                        'description':'Muna siyarda yards na maza masu kyau a farashi mai kyau, da laces, atamfofi, takalma na maza da kuma kaftans... we sell unique and amazing male yards, laces, shoes, atamfofi, and kaftans at affordable prices',
                        'category':'Fashion',
                       
                        'picture': '/vendors/Aysher_maishanu.png',
                        'state':'Kano',
                        'lga':'Kano Municipal',
                        'created_at':'11-09-2022'
                    },
            
                    'name':'PLAIN YARD',
                    'description':'2,500 per yard',
                    'sub_category':'clothing',
                    'price':2500,
                    'picture': '/products/yard.jpg',
                    'state':'Kano',
                    'lga':'Kano Municipal',
                    'created_at':'11-09-2022'
                },
                {
                    'id':7,
                     category_id:5,
                     vendor: {
                        'id':7,
                        'name':'Yaska Enterprises',
                        'description':'Utensils and Home items',
                        'category':'Home & Furniture',
                       
                        'picture': '/vendors/Yaskaenterprises.png',
                        'state':'Kaduna',
                        'lga':'Zaria',
                        'created_at':'11-09-2022'
                    },
                    'name':'Dinner Set',
                    'description':'32 pics dinner set',
                    'sub_category':'clothing',
                    'price':13000,
                    'picture': '/products/dinnerset.jpg',
                    'state':'Kaduna',
                    'lga':'Zaria',
                    'created_at':'11-09-2022'
                },
                {
                    'id':8,
                     category_id:5,
                     vendor: {
                        'id':7,
                        'name':'Yaska Enterprises',
                        'description':'Utensils and Home items',
                        'category':'Home & Furniture',
                       
                        'picture': '/vendors/Yaskaenterprises.png',
                        'state':'Kaduna',
                        'lga':'Zaria',
                        'created_at':'11-09-2022'
                    },
                    'name':'Food Warmer',
                    'description':'Set of 3 food warmer',
                    'sub_category':'Meals and Drinks',
                    'price':11500,
                    'picture': '/products/foodcontainers.jpg',
                    'state':'Kaduna',
                    'lga':'Zaria',
                    'created_at':'11-09-2022'
                },
                {
                    'id':9,
                     category_id:6,
                     vendor: {
                        'id':6,
                        'name':'Minat_veggies',
                        'description':'Muna siyarda yards na maza masu kyau a farashi mai kyau, da laces, atamfofi, takalma na maza da kuma kaftans... we sell unique and amazing male yards, laces, shoes, atamfofi, and kaftans at affordable prices',
                        'category':'Fashion',
                       
                        'picture': '/vendors/Minat_veggies.png',
                        'state':'Kano',
                        'lga':'Kumbotso',
                        'created_at':'11-09-2022'
                    } ,
                    'name':'Small Chops',
                    'description':'Feshly made',
                    'sub_category':'Meals and Drinks',
                    'price':1000,
                    'picture': '/products/smallchops.jpg',
                    'state':'Kano',
                    'lga':'Kumboto',
                    'created_at':'11-09-2022'
                }
            ]
      
      ,

      vendors:[
        {
            'id':1,
             user_id:1,
             verified:0,
            'name':'Ammis closet',
            'description':'Nice covered shoes very affordable with great quality',
            'category':'fashion',
           
            'picture': '/vendors/Ammis_closet.png',
            'state':'Bauchi',
            'lga':'Bauchi',
            'created_at':'11-09-2022'
        },
        {
            'id':2,
            user_id:1,
            verified:1,
            'name':'Arewa Failry Used',
            'description':'Discount shop...we buy and sell used household items in good condition like furnitures, electronics, and the rest',
            'category':'Services',
           
            'picture': '/vendors/Arewafairlyused.png',
            'state':'Abuja',
            'lga':'AMAC',
            'created_at':'11-09-2022'
        },
        {
            'id':3,
            user_id:1,
            verified:0,
            'name':'Kulu Beddings',
            'description':'',
            'category':'fashion',
            'picture': '/vendors/KuluBeddings.png',
            'state':'Katsina',
            'lga':'Katsina',
            'created_at':'11-09-2022'
        },
        {
            'id':4,
            user_id:2,
            verified:1,
            'name':'DUBANNI SUPPLEMENTS',
            'description':'Discount shop...we buy and sell used household items in good condition like furnitures, electronics, and the rest',
            'category':' Beauty and Personal Care',
            'picture': '/vendors/DUBANNISUPPLEMENTS.png',
            'state':'Kaduna',
            'lga':'Zaria',
            'created_at':'11-09-2022'
        },
        {
            'id':5,
             user_id:2,
             verified:1,
            'name':'Aysher_Maishanu_textiles',
            'description':'Muna siyarda yards na maza masu kyau a farashi mai kyau, da laces, atamfofi, takalma na maza da kuma kaftans... we sell unique and amazing male yards, laces, shoes, atamfofi, and kaftans at affordable prices',
            'category':'Fashion',     
            'picture': '/vendors/Aysher_maishanu.png',
            'state':'Kano',
            'lga':'Kano Municipal',
            'created_at':'11-09-2022'
        },

        {
            'id':6,
            user_id:3,
            verified:1,
            'name':'Minat_veggies',
            'description':'Muna siyarda yards na maza masu kyau a farashi mai kyau, da laces, atamfofi, takalma na maza da kuma kaftans... we sell unique and amazing male yards, laces, shoes, atamfofi, and kaftans at affordable prices',
            'category':'Fashion',
           
            'picture': '/vendors/Minat_veggies.png',
            'state':'Kano',
            'lga':'Kumbotso',
            'created_at':'11-09-2022'
        },

        {
            'id':7,
            user_id:3,
            verified:0,
            'name':'Yaska Enterprises',
            'description':'Utensils and Home items',
            'category':'Home & Furniture',
           
            'picture': '/vendors/Yaskaenterprises.png',
            'state':'Kaduna',
            'lga':'Zaria',
            'created_at':'11-09-2022'
        },
       
    ],

    categories:[
       
            { id:1,name:'Beauty & Personal Care'},
            { id:2,name:'Electronics & Appliances'},
            { id:3,name:'Fashion'},
            { id:4,name:'Food & Agriculture'},
            { id:5,name:'Home & Furniture'},
            { id:6,name:'Kids & Baby Care'},
            { id:7,name:'Phones & Accessories'},
            { id:8,name:'Services'},
      

      ],

    payments:[
       
        { id:1, vendor_name: 'Myshoes', amount:2000, paid_on: '4-10-2021', expires_on:'3-10-2022', receipt_no:'894231212'},
        { id:1, vendor_name: 'Dubanni Supplements', amount:2000, paid_on: '4-10-2021', expires_on:'3-10-2022', receipt_no:'534231212'},
        { id:1, vendor_name: 'Aysha_Maishanu', amount:2000, paid_on: '4-10-2021', expires_on:'3-10-2022', receipt_no:'823231212'},
        { id:1, vendor_name: 'Zaptrance', amount:2000, paid_on: '4-10-2021', expires_on:'3-10-2022', receipt_no:'894235352'},
        { id:1, vendor_name: 'Herbal Point', amount:2000, paid_on: '4-10-2021', expires_on:'3-10-2022', receipt_no:'8942642'},
        { id:1, vendor_name: 'Auwal_shoes', amount:2000, paid_on: '4-10-2021', expires_on:'3-10-2022', receipt_no:'53531212'},
        { id:1, vendor_name: 'shop 7', amount:2000, paid_on: '4-10-2021', expires_on:'3-10-2022', receipt_no:'534231212'},
        { id:1, vendor_name: 'shop 8', amount:2000, paid_on: '4-10-2021', expires_on:'3-10-2022', receipt_no:'6342323212'},
        { id:1, vendor_name: 'Kano used items', amount:2000, paid_on: '4-10-2021', expires_on:'3-10-2022', receipt_no:'894232512'},

  ],

      states:[
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "FCT - Abuja",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara"

      ],


      shops:[
       
        { id:1,name:'Shop 1', vendor_id:1},
        { id:2,name:'Shop 2', vendor_id:2},
        { id:3,name:'shop 3', vendor_id:2},
        { id:4,name:'Food & Agriculture', vendor_id:2},
        { id:5,name:'Home & Furniture', vendor_id:3},
        { id:6,name:'Kids & Baby Care', vendor_id:2},
        { id:7,name:'Phones & Accessories', vendor_id:3},
        { id:8,name:'Services', vendor_id:4},
  

  ],

      my_account:{
        'gender':'',
        'email':''

      },
      myprofile:{

        'avatar':'/user1.jpg',
        'gender':'Male',
        'email':'abro7@live.com',
        'type':'Custoemr',
        'status':'Valid'

      },
      user_token: null,
      current_platform: null,
      isAdmin: true,
    }
  },

  actions: {

    changeName(){

      this.name = 'helllo';

    },

    async switchPlatform(url){
    

   
    axios.defaults.baseURL=url;
    console.log('url '+axios.defaults.baseURL);
   // console.log('token '+this.user_token);
   

  },

  async storeToken(data){
    
   
   let token = data.token;
   let id = data.id;
 
    this.user_token = token;
    this.user_id = id;
    
   await store.create();
   
   await store.set('token', token);  
   await store.set('user_id', id);      
   
   
    axios.defaults.headers.common=  {'Authorization': 'Bearer '+token};
    
  
  },

  async setMeetupData(meetup){

   //  alert('meetup --'+JSON.stringify(meetup)+'--matchmaker--'+JSON.stringify(matchmaker)+'---forum-'+JSON.stringify(user));
    await store.create();


   
  if(!(await store.get('meetup_data')))
   await store.set('meetup_data', meetup);  
  
   this.meetup_data = meetup;

  },

  
  async setUserData( user){

    //  alert('meetup --'+JSON.stringify(meetup)+'--matchmaker--'+JSON.stringify(matchmaker)+'---forum-'+JSON.stringify(user));
     await store.create();
 
     if(!(await store.get('user_data')))
    await store.set('user_data', user);  
  
  
    this.user_data = user;
 
   },

   
  async setMatchmakerData( matchmaker){

    //  alert('--matchmaker--'+JSON.stringify(matchmaker));
     await store.create();
 
    if(!(await store.get('matchmaker_data')))
    await store.set('matchmaker_data', matchmaker);      
    this.matchmaker_data = matchmaker;

 
   },

  // async initializeApp(){

  //   const store = new Storage();
  //   await store.create();
  //   let token =  await store.get('token');
  //  // this.matchmaker_data =  await store.get('matchmaker_data');
  //   // this.meetup_data =  await store.get('meetup_data');
  //   // this.user_data =  await store.get('user_data_data');
  //   axios.defaults.headers.common=  {'Authorization': 'Bearer '+token};

  // }


  },

  getters:{

    getCurrentPlatform(){
      return this.current_platform;
    },

    getAccountDetails(){
      return this.my_account;
    }
  }
})