import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import VendorTabsPage from '../views/vendor/Tabs.vue'

const routes= [
  {
    path: '/',
    redirect: '/tabs/tab1'
  },
  {
    path: '/index',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/product/:id',
    component: () => import('@/views/Product.vue')
  },

  {
    path: '/category/:id',
    component: () => import('@/views/Category.vue')
  },

  {
    path: '/vendors',
    component: () => import('@/views/Vendors.vue')
  },
  {
    path: '/vendor/:id',
    component: () => import('@/views/Vendor.vue')
  },
  {
    path: '/:page/:id/products',
    component: () => import('@/views/Products.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'categories',
        component: () => import('@/views/Categories.vue')
      },
      {
        path: 'profile',
        component: () => import('@/views/Profile.vue')
      }
    ]
  },

  {
    path: '/vendor/',
    component: VendorTabsPage,
    children: [
      {
        path: '',
        redirect: '/vendor/dashboard'
      },
      {
        path: 'dashboard',
        component: () => import('@/views/vendor/Dashboard.vue')
      },
      {
        path: 'products',
        component: () => import('@/views/vendor/Products.vue')
      },
      {
        path: 'shops',
        component: () => import('@/views/vendor/Shops.vue')
      }
    ]
  },


  {
    path: '/vendor/product/:id',
    component: () => import('@/views/vendor/Product.vue')
  },

  {
    path: '/vendor/shop/:id',
    component: () => import('@/views/vendor/Shop.vue')
  },

  {
    path: '/vendor/payments',
    component: () => import('@/views/vendor/Payments.vue')
  },


  {
    path: '/contact',
    component: () => import('@/views/Contact.vue')
  },

  {
    path: '/policy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },

  {
    path: '/terms',
    component: () => import('@/views/TermsConditions.vue')
  },


  {
    path: '/guidelines',
    component: () => import('@/views/Guidelines.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
